import type {
  Organization as OrganizationSchema,
  OrganizationStudent as OrganizationStudentSchema,
} from "@sling-inc/giyoung-schema/models";
import type {
  Organization,
  OrganizationStudent,
} from "(legacy)/domain/model/Organization";
import type { WithId } from "../repositories/FirestoreUtils";
import type { FirestoreUser } from "./Student";

export type FirestoreOrganization = OrganizationSchema;

export type FirestoreOrganizationStudent = OrganizationStudentSchema;

export const toOrganization = (
  firestoreOrganization: WithId<FirestoreOrganization>
): Organization => {
  const {
    id,
    name,
    commonClassroomId,
    websiteURL,
    logoURL,
    isDriveAvailable,
    isAndroidAvailable,
    isMaterialAvailable,
    isVisible,
  } = firestoreOrganization;
  return {
    id,
    name,
    commonClassroomId,
    websiteURL,
    logoURL,
    isDriveAvailable,
    isAndroidAvailable,
    isMaterialAvailable,
    isVisible,
  };
};

export const toOrganizationStudent = (
  { id, name }: WithId<FirestoreOrganizationStudent>,
  { organizationClassroomIds }: FirestoreUser
): OrganizationStudent => {
  if (!organizationClassroomIds)
    throw new Error("Not Found User Organizations");
  return { id, name, organizationClassroomIds };
};
