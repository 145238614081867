import { from, Observable } from "rxjs";
import {
  arrayUnion,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { getJWTSecureToken } from "../providers/firebase-client";

import type {
  DocumentReference,
  Query,
  DocumentData,
  QuerySnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  FirestoreDataConverter,
} from "@firebase/firestore";
import type { HttpsCallableResult } from "@firebase/functions";

import { rejectEmpty } from "(legacy)/lib/utils";

export type WithId<T> = T & { id: string };

export function converter<T extends object>(): FirestoreDataConverter<
  WithId<T>
> {
  return {
    toFirestore(data: WithFieldValue<T>): DocumentData {
      return data;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): WithId<T> {
      const data = snapshot.data(options) as T;
      return { id: snapshot.id, ...data };
    },
  };
}

export function getDocData<T>(
  reference: DocumentReference<T>
): Promise<T | undefined> {
  return getDoc(reference).then((x) => x.data());
}

export function getReferencedDocData<T, P>(
  base: DocumentReference<T>,
  key: (docData: T) => DocumentReference<P>
): Promise<[T, P]> {
  return getDocData(base)
    .then(rejectEmpty(`Empty Base Document Data ${base.path}`))
    .then((baseData) => {
      return getDocData(key(baseData))
        .then(
          rejectEmpty(`Empty Reference Document Data ${key(baseData).path}`)
        )
        .then((referenceData) => {
          return [baseData, referenceData];
        });
    });
}

export function getSnapshotData<T>(snapshot: QuerySnapshot<T>): T[] {
  return snapshot.docs.map((x) => x.data());
}

export function getDocsData<T>(query: Query<T>): Promise<T[]> {
  return getDocs(query).then(getSnapshotData);
}

export function getReferencedDocsData<T, P>(
  query: Query<T>,
  key: (docData: T) => DocumentReference<P>
): Promise<[T, P][]> {
  return getDocsData(query).then((baseDataArray) => {
    return Promise.all(
      baseDataArray.map((baseData) => {
        return getDocData(key(baseData))
          .then(rejectEmpty(`Empty Reference Document ${key(baseData).path}`))
          .then((referenceData) => {
            return [baseData, referenceData] as [T, P];
          });
      })
    );
  });
}

export const requestHeaders = async (): Promise<Headers> => {
  const token = await getJWTSecureToken();
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");
  return headers;
};

// Todo: FirestoreObservableUtils 파일 만들기
export const getObservableQuerySnapshot = <T>(
  query: Query<T>
): Observable<QuerySnapshot<T>> =>
  new Observable<QuerySnapshot<T>>((subscriber) => {
    const unsubscribe = onSnapshot(query, (snapshot) => {
      subscriber.next(snapshot);
    });
    return (): void => {
      unsubscribe();
    };
  });
export const getObservableQuerySnapshotData = <T>(
  query: Query<T>
): Observable<T[]> =>
  new Observable<T[]>((subscriber) => {
    const unsubscribe = onSnapshot(query, (snapshot) => {
      subscriber.next(getSnapshotData(snapshot));
    });
    return (): void => {
      unsubscribe();
    };
  });

export const getObservableSnapshotRefData = <T>(
  ref: DocumentReference<T>
): Observable<T | undefined> =>
  new Observable<T>((subscriber) => {
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      subscriber.next(snapshot.data());
    });
    return () => {
      unsubscribe();
    };
  });

export const getObservableResponseDataFromGCF = <ResponseData>( // GCF: google cloud functions
  gcf: Promise<HttpsCallableResult<ResponseData>>
): Observable<ResponseData> => from(gcf.then((res) => res.data));

export const addInClassroom = (
  ref: DocumentReference,
  organizationId: string,
  classroomId: string
): Promise<void> => {
  return updateDoc(ref, {
    organizationIds: arrayUnion(organizationId),
    organizationClassroomIds: arrayUnion({
      organizationId,
      classroomId,
    }),
  });
};
