import * as Sentry from "@sentry/browser";
import { AES } from "crypto-js";

import type { Levels, Log } from "@sling-inc/type-validator/type/gcp-logger";

import { LOG_SECRET_KEY } from "(legacy)/infrastructure/providers/gcp-logger/constant";

export default async function fetchLog(
  type: Levels,
  message: string,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any
): Promise<void> {
  const log: Log = {
    type,
    message,
    meta,
  };
  const ciphertext = AES.encrypt(
    JSON.stringify(log),
    LOG_SECRET_KEY
  ).toString();
  return fetch("/api/log", { method: "POST", body: ciphertext }).then((res) => {
    if (res.status !== 200) {
      res
        .json()
        .then((errorIssue) =>
          Sentry.captureMessage("log 전송에 실패했습니다", errorIssue)
        );
    }
  });
}
