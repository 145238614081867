import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isNumber from "lodash/isNumber";

dayjs.extend(utc);

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const rejectEmpty =
  <TValue>(errorMessage: string) =>
  (value: TValue | null | undefined): Promise<TValue> => {
    if (notEmpty(value)) {
      return Promise.resolve(value);
    }
    return Promise.reject(errorMessage);
  };

export const numberWithCommas = (n: string): string =>
  `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatDatetime = (date: Date): string =>
  dayjs(date).format("YYYY-MM-DD HH:mm:ss");

export const getElapsedTimeWithKR = (elapsedSeconds: number): string => {
  if (!isNumber(elapsedSeconds)) return "-";
  const time = dayjs.utc(elapsedSeconds * 1000);

  const isOverMin = elapsedSeconds > 60;
  const isOverHour = elapsedSeconds > 3600;

  const hour = `${time.hour()}시간 `;
  const min = `${time.minute()}분 `;
  const sec = `${time.second()}초`;
  if (isOverMin && isOverHour) {
    return hour + min + sec;
  } else if (isOverMin) {
    return min + sec;
  } else {
    return sec;
  }
};

export const safeJsonParse = <T extends object>(json: string): T | null => {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
};

export const toDataURI = (data: string): string =>
  `data:image/png;base64, ${data}`;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyLengthArray = (length: number): any[] =>
  new Array(length).fill(null);

export const replaceStorageURL = (url?: string): string | undefined =>
  url
    ?.replace("https://firebasestorage.googleapis.com", "/fb-storage")
    .replace("https://storage.googleapis.com", "/gcp-storage");

export const naNToNull = (value: number): number | null =>
  isNaN(value) ? null : value;
