import { useEffect, useMemo, useState } from "react";

import type { TutorSession } from "(legacy)/domain/model/TutorSession";

import { onTutorSession } from "(legacy)/domain/model/TutorSession";
import { SESSION_DATA_COOKIE_NAME } from "(legacy)/lib/constraints";
import {
  sessionCookieOption,
  setCookieWithCompress,
} from "(legacy)/lib/cookie";

export const useSession = (
  sessionFromCookie: TutorSession | null
): TutorSession | null => {
  const [organization, setOrganization] = useState(
    sessionFromCookie?.organization
  );
  const [tutor, setTutor] = useState(sessionFromCookie?.tutor);

  const session = useMemo(() => {
    if (!sessionFromCookie) return null;

    const mergedSession = {
      ...sessionFromCookie,
      organization: organization ?? sessionFromCookie.organization,
      tutor: tutor ?? sessionFromCookie.tutor,
    };

    setCookieWithCompress(
      SESSION_DATA_COOKIE_NAME,
      JSON.stringify(mergedSession),
      sessionCookieOption
    );
    return mergedSession;
  }, [sessionFromCookie, organization, tutor]);

  const tutorId = session?.tutor?.id;
  const organizationId = session?.tutor?.organizationIds?.[0];

  useEffect(() => {
    if (!tutorId || !organizationId) return;

    const subscribe = onTutorSession(tutorId, organizationId, (newSession) => {
      setTutor(newSession.tutor);
      setOrganization(newSession.organization);
    });
    return () => subscribe.unsubscribe();
  }, [tutorId, organizationId]);

  return session;
};
