import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export function useSentry(tutorId?: string, tutorNickname?: string): void {
  useEffect(() => {
    if (tutorId && tutorNickname) setUser(tutorId, tutorNickname);
    return () => {
      clearUser();
    };
  }, [tutorId, tutorNickname]);
}

export function setUser(tutorId: string, tutorNickname: string): void {
  Sentry.setUser({
    id: tutorId,
    username: tutorNickname,
  });
}

export function clearUser(): void {
  Sentry.configureScope((scope) => scope.setUser(null));
}
