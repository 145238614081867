import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";
import { parseCookies, setCookie } from "nookies";

import type { NextPageContext } from "next";

export const expiresIn = 60 * 60 * 24 * 14 * 1000; //  The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.

export const sessionCookieOption = {
  path: "/",
  maxAge: expiresIn / 1000,
  secure: true,
};

export const setCookieWithCompress = (
  key: string,
  value: string,
  options: { path: string; maxAge: number; secure: boolean },
  context: Pick<NextPageContext, "res"> | null = null
): string => {
  const compressed = compressToEncodedURIComponent(value);
  setCookie(context, key, compressed, { ...options });

  return compressed;
};

export const getCookieWithDecompress = (
  key: string,
  context: Pick<NextPageContext, "req"> | null = null
): string => {
  const cookie = parseCookies(context)[key];
  return decompressFromEncodedURIComponent(cookie) ?? "";
};
