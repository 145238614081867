import { collection } from "firebase/firestore";
import { firestore } from "../providers/firebase-client";
import { converter } from "./FirestoreUtils";

import type { CollectionReference } from "firebase/firestore";
import type { FirestoreOrganizationPDF } from "../data-transfers/OrganizationPDF";
import type { FirestoreDriveFolder } from "../data-transfers/DriveFolder";
import type {
  FirestoreQuestion,
  FirestoreQuestionAnswer,
  FirestoreUserLikeHistory,
} from "../data-transfers/Question";
import type { FirestoreTutorNotification } from "../data-transfers/TutorNotification";
import type {
  FirestoreUser,
  FirestoreOrganizationStudentElapsedSecondsLog,
} from "../data-transfers/Student";
import type { FirestoreUserMockExam } from "../data-transfers/StudentMockExam";
import type { FirestoreUserExamPaper } from "../data-transfers/StudentExamPaper";
import type { FirestoreUserProblem } from "../data-transfers/StudentProblem";
import type { FirestoreUserTextbook } from "../data-transfers/StudentTextbook";
import type { FirestoreTutorReport } from "../data-transfers/StudentReport";
import type { FirestoreExamPaper } from "../data-transfers/ExamPaper";
import type { FirestoreMockExam } from "../data-transfers/MockExam";
import type { FirestoreProblem } from "../data-transfers/Problem";
import type { FirestorePassage } from "../data-transfers/Passage";
import type { FirestoreTextbook } from "../data-transfers/Textbook";
import type { FirestoreClassroom } from "../data-transfers/Classroom";
import type { FirestoreTutorInvitation } from "../data-transfers/TutorInvitation";
import type { FirestoreStudentInvitation } from "../data-transfers/StudentInvitation";
import type { FirestoreTutor } from "../data-transfers/Tutor";
import type { FirestoreOrganizationStudent } from "../data-transfers/Student/OrganizationStudent";
import type { FirestoreTutorReportGroup } from "../data-transfers/ClassroomReportGroup";
import type { FirestoreOrganization } from "../data-transfers/Organization";
import type {
  FirestoreALSAndroidDrawingData,
  FirestorePLSAndroidDrawingData,
} from "../data-transfers/AndroidDrawingData";
import type { FirestoreOperatedPDF } from "../data-transfers/OperatedPDF";
import type { FirestoreOperatedPDFProblem } from "../data-transfers/OperatedPDFProblem";
import type { FirestoreOperatedPDFPassage } from "../data-transfers/OperatedPDFPassage";
import type { FirestoreBlock } from "../data-transfers/Block";
import type { FirestoreAssembledLearningSheet } from "../data-transfers/AssembledLearningSheet";
import type { FirestoreClassMaterial } from "../data-transfers/Material";
import type { FirestoreUserClassMaterial } from "../data-transfers/StudentMaterial";
import type { FirestoreUserBlock } from "../data-transfers/StudentBlock";
import type { FirestoreOrganizationMaterialTag } from "../data-transfers/OrganizationMaterialTag";
import type { FirestoreStudentDailyStat } from "../data-transfers/StudentDailyStat";
import type { FirestoreClassroomDailyStat } from "../data-transfers/ClassroomDailyStat";
import type { FirestoreClassroomMaterialStat } from "../data-transfers/ClassroomMaterialStat";
import type { FirestoreOrganizationLog } from "../data-transfers/OrganizationLog";
import type { FirestoreSavedOperation } from "../data-transfers/SavedOperation";
import type { WithId } from "./FirestoreUtils";

export const examPapers = collection(firestore, "examPapers").withConverter(
  converter<FirestoreExamPaper>()
);
export const mockExams = collection(firestore, "mockExams").withConverter(
  converter<FirestoreMockExam>()
);
export const problems = collection(firestore, "problems").withConverter(
  converter<FirestoreProblem>()
);
export const passages = collection(firestore, "passages").withConverter(
  converter<FirestorePassage>()
);
export const textbooks = collection(
  firestore,
  "textbookTemplates"
).withConverter(converter<FirestoreTextbook>());

export const questions = collection(firestore, "questions").withConverter(
  converter<FirestoreQuestion>()
);

export const questionAnswers = (
  questionId: string
): CollectionReference<WithId<FirestoreQuestionAnswer>> =>
  collection(
    firestore,
    `questions/${questionId}/questionAnswers`
  ).withConverter(converter<FirestoreQuestionAnswer>());

export const users = collection(firestore, "users").withConverter(
  converter<FirestoreUser>()
);
export const userTextbooks = (
  userId: string
): CollectionReference<WithId<FirestoreUserTextbook>> =>
  collection(firestore, `users/${userId}/textbooks`).withConverter(
    converter<FirestoreUserTextbook>()
  );
export const userExamPapers = (
  userId: string
): CollectionReference<WithId<FirestoreUserExamPaper>> =>
  collection(firestore, `users/${userId}/userExamPapers`).withConverter(
    converter<FirestoreUserExamPaper>()
  );
export const userProblems = (
  userId: string
): CollectionReference<WithId<FirestoreUserProblem>> =>
  collection(firestore, `users/${userId}/userProblems`).withConverter(
    converter<FirestoreUserProblem>()
  );
export const userMaterials = (
  userId: string
): CollectionReference<WithId<FirestoreUserClassMaterial>> =>
  collection(firestore, `users/${userId}/userMaterials`).withConverter(
    converter<FirestoreUserClassMaterial>()
  );
export const userBlocks = (
  userId: string
): CollectionReference<WithId<FirestoreUserBlock>> =>
  collection(firestore, `users/${userId}/userBlocks`).withConverter(
    converter<FirestoreUserBlock>()
  );
export const userBlocksAndroidDrawingData = (
  userId: string,
  blockId: string
): CollectionReference<WithId<FirestoreALSAndroidDrawingData>> =>
  collection(
    firestore,
    `users/${userId}/userBlocks/${blockId}/androidDrawingData`
  ).withConverter(converter<FirestoreALSAndroidDrawingData>());

export const userProblemsAndroidDrawingData = (
  userId: string,
  problemId: string
): CollectionReference<WithId<FirestoreALSAndroidDrawingData>> =>
  collection(
    firestore,
    `users/${userId}/userProblems/${problemId}/androidDrawingData`
  ).withConverter(converter<FirestoreALSAndroidDrawingData>());

export const userPassagesAndroidDrawingData = (
  userId: string,
  passageId: string
): CollectionReference<WithId<FirestoreALSAndroidDrawingData>> =>
  collection(
    firestore,
    `users/${userId}/userPassages/${passageId}/androidDrawingData`
  ).withConverter(converter<FirestoreALSAndroidDrawingData>());

export const userTextbookAndroidDrawingData = (
  userId: string,
  userTextbookId: string
): CollectionReference<WithId<FirestorePLSAndroidDrawingData>> =>
  collection(
    firestore,
    `users/${userId}/textbooks/${userTextbookId}/pages`
  ).withConverter(converter<FirestorePLSAndroidDrawingData>());

export const userMockExams = (
  userId: string
): CollectionReference<WithId<FirestoreUserMockExam>> =>
  collection(firestore, `users/${userId}/userMockExams`).withConverter(
    converter<FirestoreUserMockExam>()
  );

export const userTutorReports = (
  userId: string
): CollectionReference<WithId<FirestoreTutorReport>> =>
  collection(firestore, `users/${userId}/tutorReports`).withConverter(
    converter<FirestoreTutorReport>()
  );
export const userLikeHistory = (
  userId: string
): CollectionReference<WithId<FirestoreUserLikeHistory>> =>
  collection(firestore, `users/${userId}/likeHistories`).withConverter(
    converter<FirestoreUserLikeHistory>()
  );

export const organizations = collection(
  firestore,
  "organizations"
).withConverter(converter<FirestoreOrganization>());

export const organizationLogs = (
  organizationId: string
): CollectionReference<FirestoreOrganizationLog> =>
  collection(
    firestore,
    `organizations/${organizationId}/organizationLogs`
  ).withConverter(converter<FirestoreOrganizationLog>());

export const classrooms = (
  organizationId: string
): CollectionReference<WithId<FirestoreClassroom>> =>
  collection(
    firestore,
    `organizations/${organizationId}/classrooms`
  ).withConverter(converter<FirestoreClassroom>());

export const tutors = collection(firestore, "tutors").withConverter(
  converter<FirestoreTutor>()
);

export const tutorInvitations = (
  organizationId: string
): CollectionReference<WithId<FirestoreTutorInvitation>> =>
  collection(
    firestore,
    `organizations/${organizationId}/tutorInvitations`
  ).withConverter(converter<FirestoreTutorInvitation>());

export const tutorNotifications = (
  tutorId: string
): CollectionReference<WithId<FirestoreTutorNotification>> =>
  collection(firestore, `tutors/${tutorId}/notifications`).withConverter(
    converter<FirestoreTutorNotification>()
  );

export const studentInvitations = (
  organizationId: string
): CollectionReference<WithId<FirestoreStudentInvitation>> =>
  collection(
    firestore,
    `organizations/${organizationId}/studentInvitations`
  ).withConverter(converter<FirestoreStudentInvitation>());

export const organizationStudents = (
  organizationId: string
): CollectionReference<WithId<FirestoreOrganizationStudent>> =>
  collection(
    firestore,
    `organizations/${organizationId}/students`
  ).withConverter(converter<FirestoreOrganizationStudent>());

export const organizationMaterialTags = (
  organizationId: string
): CollectionReference<WithId<FirestoreOrganizationMaterialTag>> =>
  collection(
    firestore,
    `organizations/${organizationId}/materialTags`
  ).withConverter(converter<FirestoreOrganizationMaterialTag>());

export const organizationReportGroups = (
  organizationId: string
): CollectionReference<WithId<FirestoreTutorReportGroup>> =>
  collection(
    firestore,
    `organizations/${organizationId}/tutorReportGroups`
  ).withConverter(converter<FirestoreTutorReportGroup>());

export const organizationStudentElapsedSecondsLogs = (
  organizationId: string,
  studentId: string
): CollectionReference<WithId<FirestoreOrganizationStudentElapsedSecondsLog>> =>
  collection(
    firestore,
    `organizations/${organizationId}/students/${studentId}/elapsedSecondsLogs`
  ).withConverter(converter<FirestoreOrganizationStudentElapsedSecondsLog>());

export const driveFolders = (
  organizationId: string
): CollectionReference<WithId<FirestoreDriveFolder>> =>
  collection(
    firestore,
    `organizations/${organizationId}/driveFolders`
  ).withConverter(converter<FirestoreDriveFolder>());

export const organizationPDFs = (
  organizationId: string
): CollectionReference<WithId<FirestoreOrganizationPDF>> =>
  collection(
    firestore,
    `organizations/${organizationId}/organizationPdfs`
  ).withConverter(converter<FirestoreOrganizationPDF>());

export const operatedPDFs = collection(firestore, "operatedPdfs").withConverter(
  converter<FirestoreOperatedPDF>()
);

export const operatedPDFProblems = (
  operatedPDFId: string
): CollectionReference<WithId<FirestoreOperatedPDFProblem>> =>
  collection(
    firestore,
    `operatedPdfs/${operatedPDFId}/operatedPdfProblems`
  ).withConverter(converter<FirestoreOperatedPDFProblem>());

export const operatedPDFPassages = (
  operatedPDFId: string
): CollectionReference<WithId<FirestoreOperatedPDFPassage>> =>
  collection(
    firestore,
    `operatedPdfs/${operatedPDFId}/operatedPdfPassages`
  ).withConverter(converter<FirestoreOperatedPDFPassage>());

export const blocks = collection(firestore, "blocks").withConverter(
  converter<FirestoreBlock>()
);

export const assembledLearningSheets = collection(
  firestore,
  "examPapers"
).withConverter(converter<FirestoreAssembledLearningSheet>());

export const classMaterials = collection(firestore, "materials").withConverter(
  converter<FirestoreClassMaterial>()
);

export const studentDailyStats = (
  organizationId: string,
  studentId: string
): CollectionReference<WithId<FirestoreStudentDailyStat>> =>
  collection(
    firestore,
    `organizations/${organizationId}/students/${studentId}/dailyStats`
  ).withConverter(converter<FirestoreStudentDailyStat>());

export const classroomDailyStats = (
  organizationId: string,
  classroomId: string
): CollectionReference<WithId<FirestoreClassroomDailyStat>> =>
  collection(
    firestore,
    `organizations/${organizationId}/classrooms/${classroomId}/dailyStats`
  ).withConverter(converter<FirestoreClassroomDailyStat>());

export const claassroomMaterialStats = (
  organizationId: string,
  classroomId: string
): CollectionReference<WithId<FirestoreClassroomMaterialStat>> =>
  collection(
    firestore,
    `organizations/${organizationId}/classrooms/${classroomId}/materialStats`
  ).withConverter(converter<FirestoreClassroomMaterialStat>());

export const savedOperations = (
  organizationId: string
): CollectionReference<WithId<FirestoreSavedOperation>> =>
  collection(
    firestore,
    `organizations/${organizationId}/savedOperations`
  ).withConverter(converter<FirestoreSavedOperation>());
