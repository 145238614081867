import type { Tutor } from "(legacy)/domain/model/Tutor";
import type { WithId } from "../repositories/FirestoreUtils";
import type { Tutor as TutorSchema } from "@sling-inc/giyoung-schema/models";

export type FirestoreTutor = TutorSchema;

export const toTutor = (firestoreTutor: WithId<FirestoreTutor>): Tutor => {
  const { id, name, nickname, role, organizationIds } = firestoreTutor;
  return { id, name, nickname, role, organizationIds };
};
