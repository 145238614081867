import { useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";

import type { FC } from "react";
import type { TutorSession } from "(legacy)/domain/model/TutorSession";

const projectId = process.env.NEXT_PUBLIC_GA_PROJECT_ID ?? "";

const routes = [
  {
    path: "/",
    name: "textbookTab",
  },
  {
    path: "/exam-papers",
    name: "examPaperTab",
  },
  {
    path: "/mock-exams",
    name: "mockExamTab",
  },
  {
    path: "/manage-students",
    name: "studentTab",
  },
  {
    path: "/tutors",
    name: "tutorTab",
  },
  {
    path: "/classrooms",
    name: "classTab",
  },
  {
    path: "/textbooks/.+",
    name: "textbookStat",
  },
  {
    path: "/students/.+/textbooks/.+",
    name: "studentTextbook",
  },
  {
    path: "/exam-papers/.+",
    name: "examPaperStat",
  },
  {
    path: "/students/.+/userExamPapers-.+",
    name: "studentExamPaper",
  },
  {
    path: "/mock-exams/.+",
    name: "mockExamStat",
  },
  {
    path: "/students/.+/userMockExams-.+",
    name: "studentMockExam",
  },
  {
    path: "/classrooms/.+",
    name: "classDetail",
  },
  {
    path: "/students/.+",
    name: "studentDetail",
  },
  {
    path: "/add-textbook",
    name: "addTextbook",
  },
  {
    path: "/add-exam-paper",
    name: "addExamPaper",
  },
  {
    path: "/add-mock-exam",
    name: "addMockExam",
  },
];

interface Props {
  session: TutorSession | null;
}

const GA: FC<Props> = ({ session }) => {
  const router = useRouter();

  useEffect(() => {
    const builtRoutes = routes.map((e) => ({
      ...e,
      matcher: new RegExp(`^${e.path}$`),
    }));

    const handleRouteChange = (url: string): void => {
      const route = builtRoutes.find((e) => e.matcher.test(url));

      gtag("config", projectId, {
        page_title: route?.name,
        page_path: url,
        send_page_view: true,
        user_properties: {
          organizations: session?.tutor?.organizationIds,
        },
        debug_mode: process.env.NODE_ENV === "development",
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    handleRouteChange(location.pathname);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, session]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${projectId}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
        `}
      </Script>
    </>
  );
};
export default GA;
