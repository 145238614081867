import fetchLog from "./_logFetcher";

import type { TutorSession } from "(legacy)/domain/model/TutorSession";

export const logAuthStateLogout = (tutorSession: TutorSession): Promise<void> =>
  fetchLog("info", "onAuthStateChanged - logout!..", {
    tutorSession,
  });

export const logSessionLogout = (tutorSession: TutorSession): Promise<void> =>
  fetchLog("info", "api/session-logout", {
    tutorSession,
  });

export const logInvalidCookie = (
  tutorSession: TutorSession,
  res: Response
): Promise<void> =>
  fetchLog("info", "invalid session cookie", {
    tutorSession,
    res,
  });
