"use client";

import { ConfigProvider as AntdProvider } from "antd";
import korean from "antd/locale/ko_KR";

import type { PropsWithChildren } from "react";

export const COLORS = {
  gray: {
    0: "#FFFFFF",
    1: "#FAFAFA",
    2: "#F3F3F3",
    3: "#EDEDED",
    4: "#DDDDDD",
    5: "#CCCCCC",
    6: "#A9A9A9",
    7: "#777777",
    8: "#4A4A4A",
    9: "#1A1A1A",
  },
  bluegray: {
    5: "#818EA1",
    6: "#64748B",
  },
  primary: {
    0: "#FFFFFF",
    1: "#FAFEFF",
    2: "#F3FCFD",
    3: "#EAF9FD",
    4: "#CBEDF3",
    5: "#8BD9EA",
    6: "#5BCDE3",
    7: "#06B6D4",
    8: "#059BB4",
    9: "#036475",
  },
  sub: { caution2: "#EF4949", caution1: "#FF6666", warning: "FFA826" },
};

const FONTS = {
  family: `"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`,
};

export const ConfigProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  return (
    <AntdProvider
      locale={korean}
      typography={{
        style: { marginBlockStart: 0 },
      }}
      theme={{
        token: {
          colorPrimary: COLORS.primary[7],
          colorPrimaryHover: COLORS.primary[8],
          colorPrimaryBg: COLORS.primary[3],
          colorBorderSecondary: COLORS.gray[3],
          colorText: COLORS.gray[9],
          colorTextDisabled: COLORS.gray[5],
          colorTextPlaceholder: COLORS.gray[5],
          colorErrorText: COLORS.sub.caution1,
          colorError: COLORS.sub.caution1,
          colorErrorHover: COLORS.sub.caution2,
          colorWarning: COLORS.sub.warning,
          fontFamily: FONTS.family,
          lineHeight: 1.5,
          colorIcon: COLORS.gray[6],
          colorBgBase: COLORS.gray[0],
          colorTextSecondary: COLORS.gray[7],
          colorTextDescription: COLORS.gray[6],
          colorTextTertiary: COLORS.gray[6],
        },
        components: {
          Button: {
            colorTextDisabled: COLORS.gray[6],
            colorText: COLORS.gray[9],
            controlHeight: 36,
            borderRadius: 6,
            fontSize: 14,
            fontSizeLG: 16,
          },
          Layout: {
            headerBg: COLORS.gray[0],
            footerBg: COLORS.gray[0],
            headerHeight: 60,
          },
          Typography: { titleMarginBottom: 0, titleMarginTop: 0, margin: 0 },
          Form: { itemMarginBottom: 0 },
          Slider: {
            handleActiveColor: COLORS.primary[7],
            handleColor: COLORS.primary[5],
            trackBg: COLORS.primary[5],
            trackHoverBg: COLORS.primary[6],
          },
        },
      }}
    >
      {children}
    </AntdProvider>
  );
};
