import { createContext, useContext, useEffect } from "react";
import { destroyCookie } from "nookies";
import * as Sentry from "@sentry/browser";
import { signInWithCustomToken } from "@firebase/auth";
import { useRouter } from "next/router";

import type { ReactElement } from "react";
import type { TutorSession } from "(legacy)/domain/model/TutorSession";

import {
  logAuthStateLogout,
  logInvalidCookie,
} from "(legacy)/infrastructure/service/Logging/client";
import { firebaseAuth } from "(legacy)/infrastructure/providers/firebase-client";
import { useSession } from "(legacy)/hooks/tutor-session";
import {
  SESSION_COOKIE_NAME,
  SESSION_DATA_COOKIE_NAME,
} from "(legacy)/lib/constraints";
import { useSentry } from "(legacy)/lib/sentry";

interface TutorProviderProps {
  children: ReactElement;
  tutorSession: TutorSession | null;
}

const TutorContext = createContext<TutorSession | null | undefined>(undefined);

export function TutorProvider({
  children,
  tutorSession,
}: TutorProviderProps): JSX.Element {
  const router = useRouter();
  const session = useSession(tutorSession);

  useSentry(tutorSession?.id, tutorSession?.tutor?.nickname);
  useEffect(
    () =>
      firebaseAuth.onAuthStateChanged((auth) => {
        if (auth == null && tutorSession != undefined) {
          void logAuthStateLogout(tutorSession);
          fetch("/api/custom-token").then((res) => {
            switch (res.status) {
              case 401:
              case 400: {
                void logInvalidCookie(tutorSession, res);
                destroyCookie(null, SESSION_COOKIE_NAME);
                destroyCookie(null, SESSION_DATA_COOKIE_NAME);
                setTimeout(() => {
                  void router.push("/login");
                }, 2500);
                break;
              }
              case 200: {
                res.text().then((token) => {
                  void signInWithCustomToken(firebaseAuth, token);
                  Sentry.captureMessage("재 로그인 되었습니다", {
                    level: "log",
                    extra: {
                      tutorSession,
                    },
                  });
                });
                break;
              }
            }
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <TutorContext.Provider value={session}>{children}</TutorContext.Provider>
  );
}

export const useTutorSession = (): TutorSession => {
  const tutorSession = useContext(TutorContext);
  if (tutorSession == undefined) {
    throw new Error("tutorSession 이 셋팅되지 않았습니다.");
  }
  if (tutorSession.organization === null) {
    throw new Error("organization 이 null입니다.");
  }
  return tutorSession;
};
