import { doc } from "firebase/firestore";
import { combineLatest } from "rxjs";
import { organizations, tutors } from "../GiYoungCollection";
import { toTutor } from "../../data-transfers/Tutor";
import { toOrganization } from "../../data-transfers/Organization";
import { getObservableSnapshotRefData } from "../FirestoreUtils";

import type { Subscription } from "rxjs";
import type { TutorSession } from "(legacy)/domain/model/TutorSession";

export const onTutorSession = (
  tutorId: string,
  organizationId: string,
  listener: (session: Omit<TutorSession, "id">) => void
): Subscription => {
  const $tutor = getObservableSnapshotRefData(doc(tutors, tutorId));
  const $organization = getObservableSnapshotRefData(
    doc(organizations, organizationId)
  );

  return combineLatest([$tutor, $organization]).subscribe(
    ([firestoreTutor, firestoreOrganization]) => {
      if (!firestoreTutor) throw new Error("not found tutor");
      if (!firestoreOrganization) throw new Error("not found organization");
      const tutor = toTutor(firestoreTutor);
      const organization = toOrganization(firestoreOrganization);

      listener({ tutor, organization });
    }
  );
};
