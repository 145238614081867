export const SESSION_COOKIE_NAME = "session";
export const SESSION_DATA_COOKIE_NAME = "session-data";

export const SOURCE_PDF = "pdf";
export const EXPLANATION_PDF = "explanation";
export const EXAM_PAPER = "과제";
export const ADD_EXAM_PAPER = `${EXAM_PAPER} 추가하기`;
export const CREATE_EXAM_PAPER = `${EXAM_PAPER} 만들기`;
export const EXAM_PAPER_THUMBNAIL = `${EXAM_PAPER} 표지`;
export const EXAM_PAPER_CREATING = `${EXAM_PAPER} 생성중...`;
export const EXAM_PAPER_CREATED_AND_ROUTE = `업로드가 완료되어 ${EXAM_PAPER} 화면으로 이동합니다...`;
export const EXAM_PAPER_UPLOADING = `${EXAM_PAPER}를 업로드중입니다. 몇 분 정도 소요될 수 있습니다...`;
export const INPUT_EXAM_PAPER = `${EXAM_PAPER}를 입력하세요`;
export const NOTHING_EXAM_PAPER_INFO = `${EXAM_PAPER} 정보가 없습니다.`;

export const MOCK_EXAM = "테스트";
export const ADD_MOCK_EXAM = `${MOCK_EXAM} 추가하기`;
export const CREATE_MOCK_EXAM = `${MOCK_EXAM} 만들기`;
export const MOCK_EXAM_THUMBNAIL = `${MOCK_EXAM} 표지`;
export const MOCK_EXAM_CREATING = `${MOCK_EXAM} 생성중...`;
export const MOCK_EXAM_CREATED_AND_ROUTE = `업로드가 완료되어 ${MOCK_EXAM} 화면으로 이동합니다...`;
export const MOCK_EXAM_UPLOADING = `${MOCK_EXAM}를 업로드중입니다. 몇 분 정도 소요될 수 있습니다...`;
export const VIEW_MOCK_EXAM_DRAWING = `${MOCK_EXAM} 풀이 보기`;
export const NOTHING_MOCK_EXAM_INFO = `${MOCK_EXAM} 정보가 없습니다.`;

export const TEXTBOOK = "교재";
export const TEXTBOOK_CREATING = `${TEXTBOOK} 생성중...`;
export const ADD_TEXTBOOK = `${TEXTBOOK} 추가하기`;
export const CREATE_TEXTBOOK = `${TEXTBOOK} 생성하기`;
export const TEXTBOOK_THUMBNAIL = `${TEXTBOOK} 표지`;
export const VIEW_TEXTBOOK_DRAWING = `${TEXTBOOK} 풀이 보기`;
export const NOTHING_TEXTBOOK_INFO = `${TEXTBOOK} 정보가 없습니다.`;

export const START_TIME = "시작 시간";
export const PROBLEM_COUNT = "문항 수 / 총 점";
export const END_TIME = "마감 시간";
export const EXAM_TIME = "시험 시간";
export const LEARNING_TIME = "학습 시간";
export const LEARNING_INFO = "학습 정보";
export const LEARNING_STAT = "학습 통계";
export const REVIEW_NOTE = "복습 노트(질문)";
export const TOTAL_LEARNING_TIME = "총 학습 시간";
export const AVERAGE_LEARNING_TIME = "평균 학습 시간";
export const SOLVING_TIME = "풀이 시간";
export const TOTAL_SOLVING_TIME = "총 풀이 시간";
export const AVERAGE_SOLVING_TIME = "평균 풀이 시간";
export const REVIEW_TIME = "복습 시간";
export const TOTAL_REVIEW_TIME = "총 복습 시간";
export const AVERAGE_REVIEW_TIME = "평균 복습 시간";
export const CREATE_TIME = "생성 시간";

export const TABS = {
  EXAM_PAPERS: `${EXAM_PAPER}`,
  TEXTBOOKS: `${TEXTBOOK}`,
  MOCK_EXAMS: `${MOCK_EXAM}`,
  QUESTIONS: "질문",
  STUDENTS: "학생",
  TUTORS: "선생님",
  CLASSROOMS: "클래스",
  DRIVE: "자료실",
  LEARNING_SHEETS: "학습자료",
  OPERATION: "문항 추출",
};

export const CSV_HEADERS = [
  { label: "학생명", key: "name" },
  { label: "전화번호", key: "phoneNumber" },
  { label: "과목", key: "subject" },
  { label: "유형", key: "type" },
  { label: "제목", key: "title" },
  { label: "짧은 제목", key: "shortTitle" },
  { label: "선생님 닉네임", key: "tutorNickname" },
  { label: "문제 개수", key: "problemCount" },
  { label: "질문 개수", key: "questionCount" },
  { label: "총점", key: "perfectScore" },
  { label: "업로드 일시", key: "createdAt" },
  { label: "학생 점수", key: "userScore" },
  { label: "풀이 시간", key: "elapsedTime" },
];

export const COLUMN_NAMES = {
  title: "제목",
  shortTitle: "짧은제목",
  studentUpdatedAt: "학생 업데이트",
  dataCreatedAt: "자료 업로드",
  lastShownPageIndex: "최근 본 페이지",
  pageCount: "페이지",
  subjectCategoryName: "영역(과목)",
  sectionName: "영역(과목)",
  userScore: "점수",
  totalElapsedSeconds: "풀이 시간",
  totalElapsedSecondsAfterGrade: "복습 시간",
  openDate: "오픈시간",
  startedAt: "시작시간",
  submittedAt: "종료시간",
  viewStat: "통계보기",
  price: "가격(원)",
  isVisible: "삭제여부",
  nickname: "닉이름",
  name: "이름",
  corrects: "정답 수",
  tags: "문제 태그",
  viewDrawings: "풀이보기",
  reviewed: "검토",
  viewResult: "채점결과",
  problemNumber: "문제",
  problemType: "문제유형",
  correctRate: "정답률",
  elapsedTime: "평균 풀이 시간",
  elapsedTimeAfterGrading: "평균 복습 시간",
  questionCount: "질문",
  correctUserNames: "맞힌 학생",
  inCorrectUserNames: "틀린 학생",
  adminCode: "관리코드",
  subjectName: "과목",
  csvContentTitle: "시험지 이름",
  averageTime: "평균시간",
  averageScore: "평균점수",
};

export const COLUMN_TOOLTIPS = {
  totalElapsedSeconds: "채점/제출 전까지의 학습 시간입니다.",
  totalElapsedSecondsAfterGrade: "채점/제출 후의 학습 시간입니다.",
};

export const FILTERS_SUBJECT_NAMES = [
  { text: "국어", value: "국어" },
  { text: "영어", value: "영어" },
  { text: "수학", value: "수학" },
  { text: "한국사", value: "한국사" },
  { text: "과학탐구", value: "과학탐구" },
  { text: "사회탐구", value: "사회탐구" },
  { text: "기타", value: "기타" },
];

export const PROBLEMS_DIFF_ERROR_MESSAGE =
  "문항정보의 개수와 파일 개수가 일치하지 않습니다. 😢";
export const MESSAGE_REQUIRE_START_TIME = "시작 시간을 선택해주세요.";

export const QUERY_PAGE_NUMBERS = "pageNumbers";
export const QUERY_CLASSROOM_ID = "classroomId";

export const problemsDiffErrorMessage = (
  sheetCount: number,
  positionCount: number
): string =>
  `문항정보 문제의 개수와 문제영역 문제의 개수가 일치하지 않습니다.  😢\n(문항정보 : ${sheetCount}, 문제영역: ${positionCount})`;

export const DEFAULT_PROFILE_IMAGE = "/default_profile.png";
export const RED_CORRECT_IMAGE = "/red-correct.png";
export const RED_INCORRECT_IMAGE = "/red-incorrect.png";
export const RED_NEUTRALITY_IMAGE = "/red-neutrality.png";
