/* eslint-disable import/order */
import Head from "next/head";
import React from "react";

import "dayjs/locale/ko";
import dayjs from "dayjs";
if (!process.browser) React.useLayoutEffect = React.useEffect;
// Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://reactjs.org/link/uselayouteffect-ssr for common fixes.
//  https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#gistcomment-3937704

import "../styles/globals.css";
import "tui-grid/dist/tui-grid.css";
import "@sling-inc/jspreadsheet/jspreadsheet-jsuites.css";

import { TutorProvider } from "(legacy)/contexts/TutorSession";
import GA from "components/GA";
import { ConfigProvider } from "components/07Other/ConfigProvider";

import type { AppProps } from "next/app";
import type { TutorSession } from "(legacy)/domain/model/TutorSession";

dayjs.locale("ko");

interface PageProps {
  tutorSession: TutorSession;
}

function TutorApp({ Component, pageProps }: AppProps<PageProps>): JSX.Element {
  const tutorSession = pageProps.tutorSession;

  return (
    <>
      <Head>
        {/* Note: 랜딩 마켓팅용 P*/}
        <meta
          name="facebook-domain-verification"
          content="z5f9zrng7friwyhy68z6vieyvikg0b"
        />
      </Head>

      <TutorProvider tutorSession={tutorSession} key={tutorSession?.id}>
        <ConfigProvider>
          <Component {...pageProps} />
        </ConfigProvider>
      </TutorProvider>
      <GA session={tutorSession} />
    </>
  );
}

export default TutorApp;
