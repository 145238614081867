import firebase from "firebase/compat/app";
import { initializeApp, getApp } from "firebase/app";
import { getFirestore, writeBatch } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const clientCredentials = {
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  storageBucket: `gs://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
};

if (!firebase.apps.length) {
  initializeApp(clientCredentials);
}

const firebaseAuth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
const tempStorage = getStorage(
  getApp(),
  `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}-temp`
);
const functions = getFunctions(getApp(), "asia-northeast3");

const getBatch = (): ReturnType<typeof writeBatch> => writeBatch(firestore);

const authChanged = new Promise((resolve) =>
  firebaseAuth.onAuthStateChanged(() => resolve(true))
);

const getJWTSecureToken = async (): Promise<string | undefined> => {
  if (typeof window !== "undefined") {
    await authChanged;
    const user = firebaseAuth.currentUser;
    if (!user) {
      return Promise.reject(new Error("로그인한 사용자가 없습니다"));
    }
    return user.getIdToken();
  } else {
    console.log("call getJWTSecureToken from server");
  }
};

export {
  firestore,
  storage,
  tempStorage,
  firebaseAuth,
  functions,
  getJWTSecureToken,
  getBatch,
};
